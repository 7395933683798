import React from 'react';
import { removeTags } from '../common';

export default function Social({ footerConfig, defaultAcademy }) {
    const getLink = (key) => {
        if (defaultAcademy?.social) {
            const academy_config_link =
                defaultAcademy?.social?.find((e) => e.name === key)?.link ?? '';
            return (
                academy_config_link ??
                footerConfig.find((e) => e.title === key)?.des ??
                ''
            );
        }

        return footerConfig.find((e) => e.title === key)?.des ?? '';
    };

    const socialNames = [
        { name: 'Facebook', icon: 'icon-fb' },
        { name: 'Instagram', icon: 'icon-lin' },
        { name: 'Twitter', icon: 'icon-wt' },
    ];

    return (
        <>
            {socialNames.map((socialItem) => (
                <a
                    key={socialItem.name}
                    href={removeTags(getLink(socialItem.name))}
                    target="_blank"
                    rel="noreferrer"
                    className={socialItem.icon}
                    aria-label={`${socialItem.name} of We Make Footballers.`}
                />
            ))}
        </>
    );
}
