import isEmpty from 'lodash/isEmpty';
import Link from 'next/link';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Constants from 'src/common/Constants';
import ModelManager from 'src/common/ModelManager';
import PathRoute from 'src/common/PathRoute';
import useComponentVisible from 'src/hooks/useComponentVisible';
import siteService from 'src/services/siteService';
import Image from 'next/image';
import Utils from 'src/common/Utils';
import { headerActionType } from '../../redux/actions/actionTypes';
import Social from '../../components/Social';
import countries from '../../common/Countries';
import { upperCase } from 'lodash';
import Select from 'react-select';
import countryService from '../../services/countryService';

function Footer() {
    const dispatch = useDispatch();
    const { listSite } = useSelector((state) => state.listSiteReducer);
    const [showSelect, setShowSelect] = useState(false);
    const [footerConfig, setFooterConfig] = useState([]);
    const [defaultAcademy, setDefaultAcademy] = useState({});
    const { ref, isComponentVisible, setIsComponentVisible } =
        useComponentVisible(true);
    const country =
        useSelector((state) => state.headerReducer.country) || Constants.UK;
    const defaultAcademyChange = useSelector(
        (state) => state.headerReducer.academy,
    );
    const headerReducer = useSelector((state) => state.headerReducer);
    useEffect(() => {
        if (!isEmpty(defaultAcademyChange)) {
            setDefaultAcademy(defaultAcademyChange);
            return;
        }
        setDefaultAcademy(ModelManager.getLocation());
        callFooterApi();
    }, [headerReducer]);
    useEffect(() => {
        if (!isComponentVisible && showSelect) {
            setShowSelect(!showSelect);
        }
    }, [isComponentVisible]);

    const onClickLocation = async (item) => {
        setShowSelect(!showSelect);

        try {
            const res = await siteService.getDetailSite({ id: item.ms_id });
            if (res.data.status == 200) {
                const item = res.data?.data?.site || {};
                localStorage.setItem('defaultAcademy', JSON.stringify(item));
                window.location.href = `${PathRoute.AliasHome(
                    country,
                    item.ms_alias,
                )}`;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const callFooterApi = async () => {
        try {
            const res = await siteService.getFooterConfig();
            if (res.data.status == 200) {
                setFooterConfig(res.data.data.cfg_value);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const [valueNationInput, setValueNationInput] = useState(
        country || countryService.getCountry(),
    );

    const options = countries.map((el) => {
        return {
            label: (
                <>
                    <el.flag /> {upperCase(el.country)}
                </>
            ),
            value: el.country,
        };
    });

    const valueOption = useMemo(
        () => options.find((item) => item.value === valueNationInput) ?? {},
        [valueNationInput],
    );

    const changeHandler = (value) => {
        if (value.value === 'us') {
            location.replace('https://wemakefootballers.us');
        }

        if (value.value === 'ae') {
            location.replace('https://uae.wemakefootballers.com');
        }

        return;
    };

    return (
        <div className="footer">
            <div className="container">
                <div className="box-contact">
                    <img
                        loading="lazy"
                        src={'/static-file/images/logo-white.png'}
                        alt=""
                        height={'100%'}
                        width={'100%'}
                    />
                    <ul className="list">
                        <li className="mail">
                            <Link
                                prefetch={false}
                                href={PathRoute.Contact(country)}
                                scroll>
                                Make an enquiry
                            </Link>
                        </li>

                        <li className="call">
                            {defaultAcademy && defaultAcademy.ms_phone ? (
                                <>
                                    <a href={`tel:${defaultAcademy.ms_phone}`}>
                                        {defaultAcademy.ms_phone}
                                    </a>
                                </>
                            ) : (
                                <>
                                    <a
                                        href="#"
                                        onClick={() => {
                                            setShowSelect(false);
                                            dispatch({
                                                type: headerActionType.CHANGE_LOCATION,
                                            });
                                            localStorage.setItem(
                                                'defaultAcademy',
                                                JSON.stringify(
                                                    defaultAcademyChange,
                                                ),
                                            );
                                        }}>
                                        Choose Academy Phone
                                    </a>
                                </>
                            )}
                        </li>
                        <li className="calender"> Mon-Fri 9am - 5pm </li>
                        <li style={{ paddingLeft: '0', marginTop: '-30px' }}>
                            <div ref={ref} className="custom-select">
                                <div
                                    className={`select-selected ${
                                        showSelect && 'active'
                                    }`}
                                    onClick={() => {
                                        setIsComponentVisible(true);
                                        setShowSelect(!showSelect);
                                    }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: '0 8px',
                                            }}>
                                            <Image
                                                src={
                                                    '/static-file/images/map-marker-white.svg'
                                                }
                                                width={25}
                                                height={25}
                                                alt="Map marker"
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flex: 1,
                                            }}>
                                            <span
                                                style={{ textAlign: 'center' }}>
                                                {defaultAcademy?.ms_name
                                                    ? defaultAcademy?.ms_name
                                                    : 'Select an academy'}
                                            </span>
                                        </div>

                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: '0 8px',
                                            }}>
                                            <Image
                                                src={
                                                    '/static-file/images/chevron-down-white.svg'
                                                }
                                                width={15}
                                                height={15}
                                                alt="Open academy selection dropdown"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`select-items ${
                                        !showSelect && 'select-hide'
                                    }`}>
                                    {showSelect &&
                                        listSite?.map((item) => (
                                            <div
                                                key={item.ms_id}
                                                onClick={(e) => {
                                                    onClickLocation(item);
                                                }}>
                                                {item.ms_name}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="box-right">
                    <ul className="list">
                        <li>
                            <Link
                                href={PathRoute.AboutUs(country)}
                                prefetch={false}>
                                About Us
                            </Link>
                        </li>
                        <li>
                            <Link
                                prefetch={false}
                                href={`${
                                    !isEmpty(defaultAcademy)
                                        ? PathRoute.JoinUsWithAlias(
                                              country,
                                              defaultAcademy?.ms_alias,
                                          )
                                        : PathRoute.JoinUs(country)
                                }`}
                                scroll>
                                Join Us
                            </Link>
                        </li>
                        <li>
                            <Link
                                prefetch={false}
                                href={PathRoute.SchoolTraining(country)}
                                scroll>
                                School Partnerships
                            </Link>
                        </li>
                        <li>
                            <a
                                href="https://franchisewmf.com/"
                                target="_blank"
                                rel="noreferrer">
                                Franchise With Us
                            </a>
                        </li>
                        <li>
                            <Link
                                prefetch={false}
                                href={PathRoute.ListQNA(country)}>
                                FAQs
                            </Link>
                        </li>
                        <li>
                            <Link
                                href={PathRoute.Policy(country)}
                                prefetch={false}>
                                Our Policies
                            </Link>
                        </li>
                    </ul>
                    <ul className="list">
                        <li>
                            <Link
                                href={PathRoute.Location(country)}
                                prefetch={false}>
                                Locations
                            </Link>
                        </li>
                        <li>
                            <a
                                href=" https://www.kitlocker.com/wemakefootballers/"
                                target="_blank"
                                rel="noreferrer">
                                Shop
                            </a>
                        </li>
                        <li>
                            <Link
                                prefetch={false}
                                href={`${
                                    !isEmpty(defaultAcademy)
                                        ? PathRoute.HomeNewsWithAlias(
                                              country,
                                              defaultAcademy.ms_alias,
                                          )
                                        : PathRoute.HomeNews(country)
                                }`}>
                                News
                            </Link>
                        </li>
                        <li>
                            <Link
                                prefetch={false}
                                href={`${Utils.checkAcademy(
                                    defaultAcademy,
                                    country,
                                    {
                                        routeWithAlias:
                                            PathRoute.OneTrainingWithAlias,
                                        routeWithCountry: PathRoute.OneTraining,
                                    },
                                )}`}>
                                {`1-on-1 Training`}
                            </Link>
                        </li>
                        <li>
                            <a
                                href={`https://performance.wemakefootballers.com`}
                                target={'_blank'}>
                                {`Performance`}
                            </a>
                        </li>
                        <li>
                            <a
                                href={`https://free.wemakefootballers.com/partnerships`}
                                target={'_blank'}>
                                {`Partnerships`}
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="Social">
                    <Social
                        footerConfig={footerConfig}
                        defaultAcademy={defaultAcademy}
                    />

                    <div className="menu-ft">
                        <Link
                            href={PathRoute.PoliciesPrivacy(country)}
                            prefetch={false}>
                            Privacy Policy
                        </Link>
                        |
                        <Link
                            href={PathRoute.ListQNA(country)}
                            prefetch={false}>
                            {' FAQ'}
                        </Link>
                        <span>|</span>
                        <Link href={PathRoute.Policy(country)} prefetch={false}>
                            Terms &amp; Conditions
                        </Link>
                        |
                        <Link
                            href={PathRoute.SiteMap(country)}
                            prefetch={false}>
                            Sitemap
                        </Link>
                        <div
                            className="selectNation"
                            style={{
                                width: '120px !important',
                                display: 'inline-block',
                                color: 'black',
                            }}>
                            <Select
                                options={options}
                                value={valueOption}
                                onChange={changeHandler}
                                menuPlacement="top"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
